import { Country } from 'constants/ventures';
import { Segment } from 'types/Segment';

export type SegmentMenuItem = {
  name: string;
  id: string;
};

export type CategoryMenuItem = {
  id?: string;
  label: string;
  url: string;
  urlKey?: string;
  translateUrl?: string;
  cmsKey?: string;
  highlight?: boolean;
  countries?: Country[];
  segment?: string;
  icon: string;
};

export type CategoryMenu = {
  sale?: CategoryMenuItem;
  prefixCategories?: CategoryMenuItem[];
  postfixCategories?: CategoryMenuItem[];
};

export const CATEGORY_MENU: Record<Segment, CategoryMenu> = {
  women: {
    sale: {
      label: 'Sale',
      url: '',
      translateUrl: 'women-sale-url',
      icon: '/cms/category/icons/sale.png',
      cmsKey: 'navigation_women_sale_promo',
    },
    prefixCategories: [
      {
        label: 'New in',
        url: '/c/women/new',
        cmsKey: 'navigation_women_newarrivals_promo',
        icon: '/cms/category/icons/CoatHanger.png',
      },
    ],
    postfixCategories: [
      {
        label: 'Brands',
        url: '/brands/women',
        cmsKey: 'navigation_brands_women',
        icon: '/cms/category/icons/Tag-Black.png',
      },
      {
        label: 'Earth Edit',
        url: '',
        translateUrl: 'women-earth-edit-url',
        cmsKey: 'navigation_women_earth-edit_promo',
        highlight: true,
        icon: '/cms/category/icons/GlobeHemisphereEast.png',
      },
      {
        label: 'ZALORA Outlet',
        url: '',
        icon: '/cms/2023/Lux/icon/Book.png',
        translateUrl: 'outlet-link',
        countries: ['sg', 'my', 'hk', 'tw'],
      },
    ],
  },
  men: {
    sale: {
      label: 'Sale',
      url: '',
      translateUrl: 'men-sale-url',
      icon: '/cms/category/icons/sale.png',
      cmsKey: 'navigation_men_sale_promo',
    },
    prefixCategories: [
      {
        label: 'New in',
        url: '/c/men/new',
        cmsKey: 'navigation_men_newarrivals_promo',
        icon: '/cms/category/icons/CoatHanger.png',
      },
    ],
    postfixCategories: [
      {
        label: 'Brands',
        url: '/brands/men',
        cmsKey: 'navigation_brands_men',
        icon: '/cms/category/icons/Tag-Black.png',
      },
      {
        label: 'Earth Edit',
        url: '',
        translateUrl: 'men-earth-edit-url',
        cmsKey: 'navigation_men_earth-edit_promo',
        highlight: true,
        icon: '/cms/category/icons/GlobeHemisphereEast.png',
      },
      {
        label: 'ZALORA Outlet',
        url: '',
        translateUrl: 'outlet-link',
        icon: '/cms/2023/Lux/icon/Book.png',
        countries: ['sg', 'my', 'hk', 'tw'],
      },
    ],
  },
  sports: {
    sale: {
      label: 'Sale',
      url: '',
      translateUrl: 'sports-sale-url',
      icon: '/cms/category/icons/sale.png',
      cmsKey: 'navigation_sports_sale_promo',
    },
    prefixCategories: [
      {
        label: 'New in',
        url: '/c/sports/new',
        cmsKey: 'navigation_sports_newarrivals_promo',
        icon: '/cms/category/icons/CoatHanger.png',
      },
      {
        label: 'ZALORA Exclusives',
        url: '',
        translateUrl: 'sports-zalora-exclusives-url',
        icon: '/cms/category/icons/SketchLogo.png',
      },
    ],
    postfixCategories: [
      {
        label: 'Brands',
        url: '/brands/sports',
        cmsKey: 'navigation_brands_sports',
        icon: '/cms/category/icons/Tag-Black.png',
      },
      {
        label: 'Earth Edit',
        url: '',
        translateUrl: 'sports-earth-edit-url',
        cmsKey: 'navigation_sports_earth-edit_promo',
        highlight: true,
        icon: '/cms/category/icons/GlobeHemisphereEast.png',
      },
    ],
  },
  luxury: {
    sale: {
      label: 'Sale',
      url: '',
      translateUrl: 'luxury-sale-url',
      icon: '/cms/category/icons/sale.png',
      cmsKey: 'navigation_luxury_sale_promo',
    },
    prefixCategories: [
      {
        label: 'New in',
        url: '/c/luxury/new',
        cmsKey: 'navigation_luxury_newarrivals_promo',
        icon: '/cms/category/icons/CoatHanger.png',
      },
      {
        label: 'Designers',
        url: '/brands/luxury',
        cmsKey: 'navigation_luxury_designers_promo',
        icon: '/cms/category/icons/SketchLogo.png',
      },
    ],
    postfixCategories: [
      {
        label: 'Earth Edit',
        url: '',
        translateUrl: 'luxury-earth-edit-url',
        cmsKey: 'navigation_luxury_earth-edit_promo',
        highlight: true,
        icon: '/cms/category/icons/GlobeHemisphereEast.png',
      },
    ],
  },
  beauty: {
    sale: {
      label: 'Sale',
      url: '',
      translateUrl: 'beauty-sale-url',
      icon: '/cms/category/icons/sale.png',
      cmsKey: 'navigation_beauty_sale_promo',
    },
    prefixCategories: [
      {
        label: 'New in',
        url: '/c/beauty/new',
        cmsKey: 'navigation_beauty_newarrivals_promo',
        icon: '/cms/category/icons/CoatHanger.png',
        countries: ['hk', 'id', 'my', 'ph', 'sg'],
      },
    ],
    postfixCategories: [
      {
        label: 'Brands',
        url: '/brands/beauty',
        cmsKey: 'navigation_brands_beauty',
        icon: '/cms/category/icons/Tag-Black.png',
      },
      {
        label: 'Earth Edit',
        url: '',
        translateUrl: 'beauty-earth-edit-url',
        cmsKey: 'navigation_beauty_earth-edit_promo',
        highlight: true,
        icon: '/cms/category/icons/GlobeHemisphereEast.png',
        countries: ['hk', 'id', 'my', 'ph', 'sg'],
      },
    ],
  },
  kids: {
    sale: {
      label: 'Sale',
      url: '',
      translateUrl: 'kids-sale-url',
      icon: '/cms/category/icons/sale.png',
      cmsKey: 'navigation_kids_sale_promo',
    },
    prefixCategories: [
      {
        label: 'New in',
        url: '/c/kids/new',
        cmsKey: 'navigation_kids_newarrivals_promo',
        icon: '/cms/category/icons/CoatHanger.png',
      },
      {
        label: 'Baby',
        url: '',
        translateUrl: 'kids-baby-url',
        cmsKey: 'navigation_kids_baby_promo',
        icon: '/cms/category/icons/Baby.png',
      },
      {
        label: 'Girls',
        url: '/c/kids/all?genders%5B%5D=Girls',
        cmsKey: 'navigation_kids_girls_promo',
        icon: '/cms/category/icons/GenderFemale.png',
      },
      {
        label: 'Boys',
        url: '/c/kids/all?genders%5B%5D=Boys',
        cmsKey: 'navigation_kids_boys_promo',
        icon: '/cms/category/icons/GenderMale.png',
      },
    ],
    postfixCategories: [
      {
        label: 'Brands',
        url: '/brands/kids',
        cmsKey: 'navigation_brands_kids',
        icon: '/cms/category/icons/Tag-Black.png',
      },
      {
        label: 'Earth Edit',
        url: '',
        translateUrl: 'kids-earth-edit-url',
        cmsKey: 'navigation_kids_earth-edit_promo',
        highlight: true,
        icon: '/cms/category/icons/GlobeHemisphereEast.png',
      },
    ],
  },
  lifestyle: {
    sale: {
      label: 'Sale',
      url: '',
      translateUrl: 'lifestyle-sale-url',
      icon: '/cms/category/icons/sale.png',
      cmsKey: 'navigation_lifestyle_sale_promo',
    },
    prefixCategories: [
      {
        label: 'New in',
        url: '/c/lifestyle/new',
        cmsKey: 'navigation_lifestyle_newarrivals_promo',
        icon: '/cms/category/icons/CoatHanger.png',
      },
    ],
    postfixCategories: [
      {
        label: 'Brands',
        url: '/brands/lifestyle',
        cmsKey: 'navigation_brands_lifestyle',
        icon: '/cms/category/icons/Tag-Black.png',
      },
      {
        label: 'Earth Edit',
        url: '',
        translateUrl: 'lifestyle-earth-edit-url',
        cmsKey: 'navigation_lifestyle_earth-edit_promo',
        highlight: true,
        countries: ['sg', 'my', 'ph', 'hk'],
        icon: '/cms/category/icons/GlobeHemisphereEast.png',
      },
    ],
  },
  home: {
    sale: {
      label: 'Sale',
      url: '',
      translateUrl: 'luxury-sale-url',
      icon: '/cms/category/icons/sale.png',
      cmsKey: 'navigation_luxury_sale_promo',
    },
    prefixCategories: [
      {
        label: 'New in',
        url: '/c/lifestyle/new',
        cmsKey: 'navigation_lifestyle_newarrivals_promo',
        icon: '/cms/category/icons/CoatHanger.png',
      },
    ],
  },
  hobbies: {},
};
